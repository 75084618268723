import {
  watch,
  onBeforeUnmount,
  useConfigStore,
  useDevice,
  useRuntimeConfig,
  useI18n,
  useRoute,
  useScroll,
  useNuxtApp,
  useTracker,
} from '#imports';

declare global {
  interface Window {
    tiledesk: any;
    tiledeskSettings: any;
  }
}

export const useLiveChat = (trigger = 'auto') => {
  const runtimeConfig = useRuntimeConfig();
  const config = useConfigStore();
  const device = useDevice();
  const route = useRoute();
  const nuxtApp = useNuxtApp();
  const { locale } = useI18n();
  const { jitsuEvent } = useTracker();

  const { arrivedState, isScrolling } = useScroll(nuxtApp.ssrContext ? undefined : window, { offset: { bottom: 500 } });
  if (import.meta.client) {
    watch(
      () => arrivedState.bottom,
      (arrivedBottom) => {
        if (arrivedBottom && window.tiledesk) {
          window.tiledesk.show?.();
        }
      },
    );

    watch(
      [() => config.featureFlags?.web_livechat, isScrolling],
      ([chatbotEnabled, isScrolling]) => {
        if (chatbotEnabled && !device.isCrawler && isScrolling && trigger !== 'manual') {
          initLiveChat({
            isLogEnabled: false,
            preChatFormJson: [
              {
                name: 'userFullname',
                type: 'text',
                mandatory: true,
                label: {
                  en: 'Full name',
                  default: 'Full name',
                },
                value: route.query?.name ? route.query.name : '',
              },
              {
                name: 'userEmail',
                type: 'text',
                mandatory: true,
                regex:
                  "/^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)+$/",
                label: {
                  en: 'Email',
                  default: 'Email',
                },
                errorLabel: {
                  en: 'Invalid email address',
                },
                value: route.query?.h ? safeAtob(route.query.h as string) : '',
              },
              {
                name: 'airpazCode',
                type: 'text',
                regex: /^\d{10,16}$/.source,
                label: {
                  en: 'Airpaz Code',
                  default: 'Airpaz Code',
                },
                errorLabel: {
                  en: 'Invalid airpaz code',
                },
                value: route.query?.rcv_code ? route.query.rcv_code : '',
              },
            ],
          });
        }
      },
      {
        immediate: true,
      },
    );
  }

  const initLiveChat = (tiledeskSettingsObj = {}) => {
    const src = `${runtimeConfig.public.chatbotUrl}/widget/launch.js`;

    const projectids = runtimeConfig.public.chatbotProjectIds.split(',').reduce((ids, value) => {
      const [lang, id] = value.split(':');

      if (!lang || !id) {
        return ids;
      }

      ids[lang] = id;

      return ids;
    }, {} as Record<string, string>);

    const projectid = projectids[locale.value] || projectids.en;

    if (!projectid) {
      return;
    }
    window.tiledeskSettings = {
      projectid,
      // marginY: 'var(--tiledesk-bottom-inset)',
      showAttachmentButton: true,
      fileUploadAccept: 'image/*',
      ...tiledeskSettingsObj,
    };

    (function (d, s, id) {
      const w = window;
      // eslint-disable-next-line
      var d = document
      // eslint-disable-next-line
      var i = function() {
        // @ts-expect-error
        // eslint-disable-next-line prefer-rest-params
        i.c(arguments);
      };
      // @ts-expect-error
      i.q = [];
      // @ts-expect-error
      i.c = function (args) {
        // @ts-expect-error
        i.q.push(args);
      };
      // @ts-expect-error
      w.Tiledesk = i;
      w.Tiledesk('onOpen', function () {
        jitsuEvent('user-click-button', {
          object_name: 'live-chat',
          object_parameter: 'live-chat-button',
          email: route.query?.h ? safeAtob(route.query.h as string) : '',
          rcv_code: route.query?.rcv_code ? route.query.rcv_code : '',
        });
      });
      let js;

      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      // eslint-disable-next-line
      js = d.createElement(s)
      js.id = id;
      // @ts-expect-error
      js.async = true;
      // @ts-expect-error
      js.src = src;
      // @ts-expect-error
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'tiledesk-jssdk');
  };
  onBeforeUnmount(() => {
    if (window.tiledesk) {
      window.tiledesk.hide?.();
    }
  });
  return {
    initLiveChat,
  };
};
